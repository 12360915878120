<template>
  <div class="HomePage">
    <CreekVideoPanel/>
  </div>
</template>

<script>

import CreekVideoPanel from '@/lib/creek/components/VideoPanel'

export default {
  name: 'Home',
  components: {
    CreekVideoPanel,
  }
}

</script>
