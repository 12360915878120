<template>
  <div class="creek-chat-panel">
    
  </div>
</template>

<script>
export default {
  name: 'ChatPanel',
  data(){
    return {}
  },
  props: {},
  methods: {

  },
  created(){

    // Connect to Laravel Websocket server.

  }
}
</script>

<style scoped lang="sass">


</style>
