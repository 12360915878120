import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomePage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/schedule',
    name: 'SchedulePage',
    component: () => import('../views/SchedulePage.vue')
  },
  {
    path: '/schedule/weekday/:weekdayNumber',
    name: 'SchedulePage',
    props: true,
    component: () => import('../views/SchedulePage.vue')
  },
  {
    path: '/calendar',
    name: 'CalendarPage',
    props: true,
    component: () => import('../views/CalendarPage.vue')
  },
  {
    path: '/shows/:showName',
    name: 'Show',
    props: true,
    component: () => import('../views/ShowPage.vue')
  },
  {
    path: '/broadcasts/:broadcastId',
    name: 'BroadcastPage',
    props: true,
    component: () => import('../views/BroadcastPage.vue')
  },
  {
    path: '/profiles/:profileId',
    name: 'ProfilePage',
    props: true,
    component: () => import('../views/ProfilePage.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
