<!-- 

TODO:

- Link users to ProfilePage.
- Link show to ShowPage.
- Add current song.
- Add "Audio Only link.
- Move BroadcastPanel under the Show and just have ChatPanel on the sidebar.
- Add Chat to the BroadcastEditor.

- Display next show by using the /timeline route instead.

- Only update the "current" object by websocket.

- Refresh /current on the WebsocketServer every 10 seconds.

- Add current.track to the current route.


- Tell DJs to update their user profiles.
- Tell DJs to add an image to their show.
- Tell DJs to log in and go to their broadcast while doing their show.

-->

<template>
  <div class="creek-broadcast-panel">
    
    <h4>ON AIR</h4>

    <!-- Show -->
    <div v-if="current && current.show" class="show">
      <h2 v-text="current.show.title"></h2>
      <p v-if="current.show.summary" v-text="current.show.summary"></p>
      <p v-if="current.show.description" v-text="current.show.description"></p>
    </div>
    <div v-if="!current || !current.show" class="show">
      <h2 v-text="Creek.studio.title"></h2>
    </div>

    <!-- Time -->
    <p class="creek-time" v-if="current && current.segment">
      <span v-text="formatTime(current.segment.start)"></span> -
      <span v-text="formatTime(current.segment.end)"></span>
    </p>

    <!-- Users -->
    <div 
      class="creek-users" 
      v-if="current && current.show && current.show.users"
    >
      <!-- TODO: Link to Profile -->
      <!--
      <router-link 
        :to="`/profiles/${user.id}`"
        class="user" 
        v-for="user in current.show.users" 
        v-text="user.display_name || user.name" 
      />
      -->
      <b 
        class="creek-user" 
        v-for="user in current.show.users" 
        v-text="user.display_name || user.name" 
      ></b>
    </div>

    <!-- Broadcast -->
    <div class="creek-broadcast">
      <h3 
        v-if="current && current.broadcast"
        v-text="current.broadcast.title"
      ></h3>
    </div>

    <div class="creek-audio-only">
      <a 
        href="https://werw-stream.creek.org/stream" 
        target="blank"
        class="audio-only-button"
      >
        Listen to Audio Only
      </a>
    </div>

  </div>
</template>

<script>

import fecha from 'fecha'

export default {
  name: 'CreekBroadcastPanel',
  props: {},
  data(){
    return {
      refreshInterval: null,
      current: {},
    }
  },
  methods: {
    async refresh(){ 
      this.current = await this.Creek.get('current')
      // X('refreshing')
    },
    formatTime(time){
      var parsedTime = fecha.parse(time, 'isoDateTime');
      return fecha.format(parsedTime, 'h:mma');
    },
  },
  created(){
    // X('wooof')
    this.refresh()
    this.refreshInterval = setInterval(this.refresh, 10000)
  }
}
</script>
