<template>
  <div id="app">
    <nav>
      <router-link to="/">Home</router-link>
      <router-link to="/shows">Shows</router-link>
    </nav>
    <main>
      <div class="nav-spacer"></div>
      <router-view/>
    </main>
    <aside>
      <div class="nav-spacer"></div>
      <CreekBroadcastPanel/>
      <CreekChatPanel/>
    </aside>
  </div>
</template>

<style lang="sass">

@import "./sass/werw.sass"

</style>

<script>

import CreekChatPanel from '@/lib/creek/components/ChatPanel.vue'
import CreekBroadcastPanel from '@/lib/creek/components/BroadcastPanel.vue'

export default {
  name: 'App',
  components: {
    CreekChatPanel,
    CreekBroadcastPanel,
  }
}

</script>
