/*
===========================================================

  Website Framework: Main Library

===========================================================
*/

/*
-----------------------------------------------------------
  Modules
-----------------------------------------------------------
*/

// NPM
import _ from 'lodash'
import axios from 'axios'
import Vue from 'vue'

// Local
import App from './App.vue'
import router from './router'

/*
-----------------------------------------------------------
  Shortcuts
-----------------------------------------------------------
*/

window.X = console.log

/*
-----------------------------------------------------------
  Axios Instance
-----------------------------------------------------------
*/

// let URLPrefix = `/`
let URLPrefix = `https://werw.studio.creek.org/`
// let URLPrefix = `https://${window.location.hostname}/`

// Remove the port number when on dev interface, 
// so that it goes to: "...org/api/xxx" 
if(window.location.port >= 8080){
  let URLPrefix = `https://werw.studio.creek.org/`
  // URLPrefix = `${window.location.protocol}//werw.dev-studio.creek.org/`
  // URLPrefix = `${window.location.protocol}//${window.location.hostname}/`
}

// Use API path as default baseURL.
const axiosInstance = axios.create({
  baseURL: URLPrefix + 'api/',
  timeout: 10000,
})


/*
-----------------------------------------------------------
  Request Handlers
-----------------------------------------------------------
*/

const handleError = (error) => {
  
  errorMessage = error.message || error
  X('Error: ' + errorMessage)

  // TODO: Log to Sentry.

}

const handleAxiosResponseError = (axiosError) => {
  
  // TODO: Log to Sentry.
  
  // console.log(axiosError)

  // Get error from data if available.
  if(_.get(axiosError, 'response.data.error')){
    console.log(axiosError.response.data.error)
    Creek.response.error = axiosError.response.data.error
  }

  if(_.get(axiosError, 'response.data.errors')){
    console.log('woop')
    console.log(axiosError.response.data.errors)
    Creek.response.errors = axiosError.response.data.errors
  }

  return null

}

const responseHandler = async (axiosRequest) => {
  
  let response = null

  try{
    response = await axiosRequest
  }catch(responseError){
    // console.log(responseError)
    // console.log(responseError.response)
    return handleAxiosResponseError(responseError)
  }

  // Handle 200 response with error in its JSON.
  if(response.data && response.data.error){
    handleError(response.data.error)
    return null
  }

  // Handle responses both with and without data property.
  if(response.data && response.data.data){
    return response.data.data
  }else if(response.data){
    return response.data
  }

}

/*
-----------------------------------------------------------
  Creek Object
-----------------------------------------------------------
*/

let Creek = {

  config: {
    studio: {
      name: 'werw',
    }
  },

  studio: {
    name: 'werw',
    title: 'WERW',
  },

  get: async (resourceName, resourceId = null) => {
    
    let path = resourceName

    path = path.replace(/\./g, '/')

    if(resourceId){
      path += '/' + resourceId
    }

    X('GET: ' + path)

    const result = await responseHandler(
      axiosInstance.get(path)
    )

    return result

  },

}

/*
-----------------------------------------------------------
  Vue
-----------------------------------------------------------
*/

Vue.prototype.Creek = Creek

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
